import React, { useState } from "react";
import { Button, Form, Input, Modal, Row, Col, Select } from "antd";

import { useAppSelector } from "../../store/hooks";
import { countryData } from "../../config/countryData";
import { groupWishlistCheckout } from "../../store/services/groupWishlist";

import { useNavigate } from "react-router-dom";

interface Props {
  groupWishlistId: string;
  stock: number;
}

const GroupWishlistCheckout: React.FC<Props> = ({ groupWishlistId, stock }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const [cities, setCities] = useState<Array<{ value: string; label: string }>>(
    []
  );

  const navigate = useNavigate();

  const showModal = () => {
    form.setFieldValue("country", "United Statess");
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const obj = {
      shippingAddress: { ...values },
      billingAddress: { ...values },
      groupWishlistId: groupWishlistId,
    };
    const res = await groupWishlistCheckout(obj);
    if (res) {
      handleCancel();
      navigate("/settings?active=orderHistory");
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        type="primary"
        className="primaryButton"
        style={{ paddingInline: "20px", height: "45px" }}
        onClick={showModal}
        disabled={stock > 0 ? false : true}
      >
        Buy
      </Button>
      <Modal
        forceRender
        title={"Checkout"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        centered
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            if (e.state) {
              form.setFieldValue("city", undefined);
              setCities(
                countryData[e.state]?.map((v: string) => {
                  return {
                    value: v,
                    label: v,
                  };
                })
              );
            }
          }}
        >
          <div className="checkout_body">
            <div className="cb_left">
              <Row gutter={[20, 0]}>
                <Col xs={24} sm={12}>
                  <h3 className="heading" style={{ marginTop: "24px" }}>
                    Shipping address
                  </h3>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter your full name" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Address line 1"
                    name="addressLine1"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter address" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Address line 2"
                    name="addressLine2"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter address" />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    name={"country"}
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={
                        <span className="select-placeholder">
                          Select Country
                        </span>
                      }
                      defaultValue={"United States"}
                      optionFilterProp="children"
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={["United States"]?.map((v) => {
                        return {
                          value: v,
                          label: v,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={"state"}
                    label="State"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={
                        <span className="select-placeholder">Select State</span>
                      }
                      optionFilterProp="children"
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={Object?.keys(countryData)?.map((v) => {
                        return {
                          value: v,
                          label: v,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="City"
                    name="city"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={
                        <span className="select-placeholder">Select city</span>
                      }
                      optionFilterProp="children"
                      filterOption={(
                        input: string,
                        option?: { label: string; value: string }
                      ) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={cities}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Zip"
                    name="zipCode"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      placeholder="Enter zip code"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    label="Phone"
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      placeholder="Enter phone no."
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <div className="shareBtn">
                    <Button
                      loading={loading}
                      type="primary"
                      className="primaryButton"
                      style={{ width: "100%", height: "50px" }}
                      htmlType="submit"
                    >
                      Confirm Delivery
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default GroupWishlistCheckout;
