import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  DatePicker,
  Tooltip,
  Avatar,
} from "antd";
import { useAppSelector } from "../../../store/hooks";
import { UpdateUserType } from "../../../store/interfaces/Auth";
import { updateUser } from "../../../store/services/auth";
import { countryData } from "../../../config/countryData";
import dayjs from "dayjs";
import { FaUpload } from "react-icons/fa6";

const PersonalInformation: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [file, setFile] = useState<string | null>(null);
  const [cities, setCities] = useState<Array<{ value: string; label: string }>>(
    []
  );



  useEffect(() => {
    if (user) {
      form.setFields([
        { name: ["firstName"], value: user?.firstName },
        { name: ["lastName"], value: user?.lastName },
        { name: ["email"], value: user?.email },
        { name: ["phoneNumber"], value: user?.phoneNumber },
        { name: ["points"], value: user?.points || 0 },
        { name: ["addressLine"], value: user?.addressLine },
        { name: ["residence"], value: user?.residence },
        { name: ["state"], value: user?.state },
        { name: ["city"], value: user?.city },
        { name: ["zipCode"], value: user?.zipCode },
        { name: ["dateOfBirth"], value: dayjs(user?.dateOfBirth) },
        { name: ["joiningDate"], value: dayjs(user?.joiningDate) },
      ]);
    }
    if (user?.state) {
      setCities(
        countryData[user?.state]?.map((v: string) => {
          return {
            value: v,
            label: v,
          };
        })
      );
    }
  }, [user]);

  const onFinish = async (values: UpdateUserType) => {
    setLoading(true);
    try {
      const obj = { ...values, country: "United States" };
      delete obj.email;

      if (file) {
        const formData = new FormData();
        Object.keys(obj).forEach((key) => {
          // @ts-ignore
          formData.append(key, obj[key]);
        });
        formData.append('storecsv', file);
        await updateUser(formData);
      } else {
        await updateUser(obj);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleFile = (file: any) => {
    setFile(file);
    const src: string = URL.createObjectURL(file);
    setImageSrc(src);
  };


  return (
    <div className="personal_info_box">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        autoComplete="off"
        onValuesChange={(e) => {
          if (e.state) {
            setCities(
              countryData[e.state]?.map((v: string) => {
                return {
                  value: v,
                  label: v,
                };
              })
            );
            form.setFieldValue("city", undefined);
          }
        }}
      >
        <Row gutter={[30, 10]}>

          {user?.badges && user?.badges?.length > 0 && (
            <>
              <Col xs={24}>
                <div style={{ marginBlock: "10px" }}>
                  <h2 className="setting_heading_small">Badges</h2>
                </div>
              </Col>
              <Col xs={24}>
                <div className="user_badge_box_container">
                  {user?.badges?.map((v, i) => {
                    return (
                      <div key={i} className="badge-box">
                        <div
                          className="img_box"
                          style={{
                            backgroundImage: `url(${v?.imageUrl})`,
                            border: "1px solid rgb(192, 192, 192)",
                          }}
                        />
                        <div className="text_box">
                          <p className="name">{v?.name}</p>
                          <Tooltip title={v?.description}>
                            <p className="description">{v?.description}</p>
                          </Tooltip>
                          <p className="description">({v?.count})</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </>
          )}
          <Col xs={24}>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <Avatar
                size={70}
                src={imageSrc ? imageSrc : user?.imgUrl ? user?.imgUrl : imageSrc
                  ? imageSrc
                  : require("../../../assets/images/user-icon.jpg")}
              />
              <Form.Item
                name={"imageUrl"}
                label={user?.firstName + " " + user?.lastName}
              >
                <>
                  <label className="file_button" htmlFor="fileInput">
                    <div className="general-input">
                      <FaUpload size={15} color="var(--mainBlue)" /> Upload Here
                    </div>
                    <input
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        height: "100%",
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      type="file"
                      hidden
                      id="fileInput"
                      onChange={(e: any) => {
                        handleFile(e?.target?.files[0]);
                      }}
                      accept="image/jpg,image/png,image/jpeg"
                    />
                  </label>
                </>
              </Form.Item>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="First name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Last name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter last name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Phone"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                placeholder="Enter phone number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                size="large"
                type="email"
                placeholder="Enter email address"
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Date of Birth"
              name={"dateOfBirth"}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <>
                {/* @ts-ignore */}
                <DatePicker
                  format="MM/DD/YYYY"
                  disabled style={{ width: "100%" }} size="large" />
              </>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Work Anniversary"
              name={"joiningDate"}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <>
                {/* @ts-ignore */}
                <DatePicker format="MM/DD/YYYY"
                  disabled style={{ width: "100%" }} size="large" />
              </>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div style={{ marginBlock: "10px" }}>
              <p className="setting_heading_small">Address</p>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Address Line"
              name="addressLine"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Suite/Apt/Floor #"
              name="residence"
            >
              <Input size="large" placeholder="Enter Suite/Apt/Floor #" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={"state"}
              label="State"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={
                  <span className="select-placeholder">Select State</span>
                }
                optionFilterProp="children"
                filterOption={(
                  input: string,
                  option?: { label: string; value: string }
                ) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={Object?.keys(countryData)?.map((v) => {
                  return {
                    value: v,
                    label: v,
                  };
                })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={
                  <span className="select-placeholder">Select city</span>
                }
                optionFilterProp="children"
                filterOption={(
                  input: string,
                  option?: { label: string; value: string }
                ) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={cities}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" type="number" placeholder="Enter zip code" />
            </Form.Item>
          </Col>
          {/* <Col xs={24}>
            <ConnectedCard />
          </Col>
          <Col xs={24}>
            <p className="setting_heading_small">Add another card</p>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Account number"
              name="accountNumber"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input
                size="large"
                type="number"
                placeholder="Enter account number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Account title"
              name="accountTitle"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" placeholder="Enter account title" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="DOE"
              name="dateOfExpire"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
                // {
                //   validator: validateExpiryDate,
                // },
              ]}
            >
              <Input size="large" placeholder="MM/YY" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Cvv"
              name="cvv"
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            >
              <Input size="large" type="number" placeholder="Enter CVV" />
            </Form.Item>
          </Col> */}
          <Col xs={24}>
            <Button
              loading={loading}
              type="primary"
              className="primaryButton"
              style={{ width: "100%", height: "50px" }}
              htmlType="submit"
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

// const ConnectedCard: React.FC = () => {
//   return (
//     <div className="conneted_card_box">
//       <p className="setting_heading_small">Connected Card</p>
//       {Array(1)
//         .fill({
//           name: "Harry Maguire",
//           cardNumber: "34783xxxxc9839",
//           date: "May 14, 2023 at 08:36pm",
//         })
//         ?.map((v, i) => {
//           return (
//             <div key={i} className="conneted_card_card">
//               <div className="cdc_left">
//                 <p className="card_name">{v?.name}</p>
//                 <div className="card_number_box">
//                   <img
//                     src={require("../../../assets/images/visa.png")}
//                     alt={"Visa"}
//                   />
//                   <p>{v?.cardNumber}</p>
//                 </div>
//                 <p className="card_date">{v?.date}</p>
//               </div>
//               <div className="cdc_right">
//                 <Button type="primary" className="outlinedButton">
//                   <span className="gradientText">Logout</span>
//                 </Button>
//               </div>
//             </div>
//           );
//         })}
//     </div>
//   );
// };

export default PersonalInformation;
