import React, { useEffect, useRef, useState } from "react";
import { ColorType } from "../../store/interfaces/Product";
import deleteICON from "../../assets/images/deleteICON.svg";
import { Divider, InputNumber, Spin } from "antd";
import minus from "../../assets/images/minus.svg";
import plus from "../../assets/images/plus.svg";
import Points from "../Points";
import { deleteCart, updateCart } from "../../store/services/cart";
import { useAppSelector } from "../../store/hooks";
import { CartProductType } from "../../store/interfaces/Cart";

interface CartCardProps {
  data: CartProductType;
  divider?: boolean;
}

interface SelectedSize {
  _id: string;
  size: string;
  colors: ColorType[];
}

const CartCard: React.FC<CartCardProps> = ({ data, divider }) => {
  const { product, color, size, quantity } = data;
  const cart = useAppSelector((state) => state?.cart?.cart?.[0]);
  const [itemSize, setItemSize] = useState<SelectedSize>();
  const [itemColor, setItemColor] = useState<ColorType>();
  const [itemCount, setItemCount] = useState(quantity || 1);
  const [selectedColor, setSelectedColor] = useState<ColorType | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleIncrease = () => {
    setLoading(true);
    setItemCount(itemCount + 1);
  };

  const handleDecrease = () => {
    setLoading(true);
    if (itemCount > 1) {
      setItemCount(itemCount - 1);
    } else if (itemCount === 1) {
      setLoading(false);
    }
  };

  useEffect(() => {
    let filteredSize = product?.variations?.filter(
      (item) => item?._id === size?.toString()
    );
    setItemSize(filteredSize?.[0]);
    let filteredColor = filteredSize?.[0]?.colors?.filter(
      (item) => item?._id === color
    );
    setItemColor(filteredColor?.[0]);
    setSelectedColor(filteredColor?.[0]);
  }, [data]);

  const handleDelete = async () => {
    setLoading(true);
    await deleteCart(cart?._id, data?._id);
    setLoading(false);
  };

  const handleUpdate = async () => {
    await updateCart({
      _id: cart?._id,
      productId: data?._id,
      quantity: itemCount,
      points: selectedColor?.points,
      size: itemSize?.size,
      color: itemColor?.hex,
    });
    setLoading(false);
  };

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    handleUpdate();
  }, [itemCount]);

  return (
    <div className="cartCard">
      <div className="cartContainer">
        <div className="productContent">
          <div
            className="productImage"
            style={{ backgroundImage: `url(${product?.imagesUrl?.[0]})` }}
          />
          <div className="productDescription">
            <p>
              {product?.name?.length > 30
                ? `${product?.name?.slice(0, 30)}...`
                : product?.name}
            </p>
            {itemSize?.size === "Fixed" ||
              ((data?.product?.variations
                ? data?.product?.variations?.length > 0
                : false) && (
                <>
                  <div className="labelName">
                    <label>Size:</label>
                    <p>{itemSize?.size}</p>
                  </div>
                </>
              ))}
            {itemColor && (
              <>
                <div className="labelName">
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      gap: ".3rem",
                    }}
                  >
                    <label>Color:</label>
                    <p
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: itemColor?.hex,
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="points">
              <Points
                points={
                  selectedColor?.points
                    ? selectedColor?.points
                    : data?.product?.points
                }
              />
            </div>
            {!!data?.product?.deliveryFee && (
              <div
                className="points"
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <label>Delivery Fee: </label>
                <Points points={data?.product?.deliveryFee} />
              </div>
            )}
          </div>
        </div>
        <div className="del-item-count">
          {loading ? (
            <Spin className="deleteBTN" />
          ) : (
            <img
              src={deleteICON}
              onClick={handleDelete}
              alt="delete-icon"
              className="deleteBTN"
            />
          )}

          <div className="plus-minus">
            {loading ? (
              <></>
            ) : (
              <>
                <img src={minus} alt="minus" onClick={handleDecrease} />
                <InputNumber
                  type="number"
                  className="ITEM_COUNT"
                  value={itemCount}
                  min={1}
                  onChange={(e) => {
                    const newValue = Number(e);
                    if (newValue > 0) {
                      setItemCount(newValue);
                    }
                  }}
                />
                <img src={plus} alt="plus" onClick={handleIncrease} />
              </>
            )}
          </div>
        </div>
      </div>
      {divider && <Divider />}
    </div>
  );
};

export default CartCard;
