
import { privateAPI } from "../../config/constants";
import { notification } from "antd";
import { setLoading, setNotificationCount, setNotificationUnreadCount, setNotifications } from "../slices/notificationSlice";
import store from "..";

export const getAllNotifications = async (payload: any) => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.post("/notification/get-all", payload);
    if (response) {
      store.dispatch(setNotifications(response?.data?.notifications));
      store.dispatch(setNotificationCount(response?.data?.data?.total));
      return response?.data;
    }
  } catch (error: any) {
    console.log("GET NOTIFICATIONS ERROR: ", error);
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getNotificationUnreadCount = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/notification/get-counts");
    console.log("🚀 ~ getNotificationUnreadCount ~ response:", response.data)
    if (response) {
      store.dispatch(setNotificationUnreadCount(response?.data?.unreadCount));
    }
    return response?.data?.unreadCount;
  } catch (error: any) {

  } finally {
    store.dispatch(setLoading(false));
  }
}

export const updateNotifications = async (data: any) => {
  try {
    const res = await privateAPI.post("/notification/read-notification", { ids: data });
    if (res.status === 200) {
      notification.success({ message: "Notification read" });
      return res.status;
    }
  } catch (error) {
    console.log("error", error);
  }
}

export const deleteNotification = async (notificationId: {id:string}) => {
  try {
    const res = await privateAPI.post("/notification/delete-notification", notificationId);
    if (res.status === 200) {
      return res.status;
    }
  } catch (error) {
    console.log("error", error);
  }
}