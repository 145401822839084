import React from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { RewardsType } from "../../store/interfaces/Rewards";
import { useAppSelector } from "../../store/hooks";
import { calculateThreeMonthsLater, isThreeMonthsOld } from "../../utils";

interface Props {
  data: RewardsType;
}

const RewardCard: React.FC<Props> = ({ data }) => {
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <div
      className="RewardCard"
      onClick={() => {
        if (isThreeMonthsOld(user?.createdAt || new Date())) {
          navigate("/order-summary", { state: { rewardData: data } });
        } else {
          notification.error({
            message: `You have not purchased a reward before ${calculateThreeMonthsLater(
              user?.createdAt || new Date()
            )}`,
          });
        }
      }}
    >
      <div
        className="product_image"
        style={{
          backgroundImage: `url(${require("../../assets/images/reward-bg.png")})`,
        }}
      >
        <div className="rewardPoints">
          <p>{data?.points}</p>
        </div>
      </div>
      <div className="product_detail">
        <p className="product_points">{data?.points} Points</p>
        <p className="product_price">${data?.price}</p>
      </div>
    </div>
  );
};

export default RewardCard;
