import { createSlice } from "@reduxjs/toolkit";
import { NotificationStateType } from "../interfaces/Notification";

const initialState: NotificationStateType = {
  loading: false,
  notifications: [],
  notificationUnreadCount: 0,
  notificationCount: 0,
};

export const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setNotifications(state, action) {
          state.notifications = action?.payload;
        },
        setNotificationCount(state, action) {
            state.notificationCount = action?.payload;
        },
        setNotificationUnreadCount(state, action) {
          state.notificationUnreadCount = action?.payload;
        }
    }
})

export const { setLoading, setNotifications, setNotificationCount, setNotificationUnreadCount } = notificationSlice.actions;

export default notificationSlice.reducer;