import React, { useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Select,
  Tooltip,
  notification,
} from "antd";

import PropTypes from "prop-types";

import rewardshare from "../../assets/images/rewardShare.svg";
import rewardStar from "../../assets/images/reward-star.svg";

import { useAppSelector } from "../../store/hooks";
import { ShareRewardsType } from "../../store/interfaces/Rewards";
import { shareRewards } from "../../store/services/rewards";

import { IoIosAlert } from "react-icons/io";

interface RewardModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const RewardModal: React.FC<RewardModalProps> = ({ isVisible, onClose }) => {
  const { user, allUsers } = useAppSelector((state) => state.auth);
  const { badges, settings } = useAppSelector((state) => state.settings);
  const [form] = Form.useForm();
  const [userName, setUserName] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [badgeId, setBadgeId] = useState<string | null>(null);
  const [points, setPoints] = useState<number>(0);

  const badgeContainerRef: any = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.pageX - badgeContainerRef.current.offsetLeft);
    setScrollLeft(badgeContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: any) => {
    if (!isDragging) return;
    const x = e.pageX - badgeContainerRef.current.offsetLeft;
    const walk = x - startX;
    badgeContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const getLimit = () => {
    const currentDate = new Date();
    if (settings && settings.exceedDate) {
      const givenDate = new Date(settings.exceedDate);
      return (givenDate < currentDate) || !settings.sharedLimit;
    } else {
      return false;
    }
  };

  const onFinish = async (values: ShareRewardsType) => {
    setLoading(true);
    try {
      if (settings && points > settings?.sharedLimit) {
        notification.error({
          message: `You cannot share more than (${Number(
            settings?.sharedLimit
          )}) points.`,
        });
        return;
      }

      if (user?.points && points <= user?.points) {
        // @ts-ignore
        const isBadge = badges?.find((v) => v?._id === badgeId);
        if (!isBadge) {
          notification.error({ message: "Select the batch first to proceed further" });
          return;
        }
        const point = isBadge?.points;
         const myBadgeId = isBadge._id;
        const res = await shareRewards({
          ...values,
          points : point,
          BadgeId:myBadgeId,
        });
        if (res?.status === 200) {
          clear();
          notification.success({
            message: `Your rewards have been successfully sent to ${userName}` || res?.data?.data?.message,
          });
          setUserName("");
        }
        setLoading(false);
      } else {
        notification.error({
          message: `You don't have enough points. Your current point total is ${user?.points}.`,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    onClose();
    form.resetFields();
    setBadgeId(null);
  };

  const getData = (value: string, option: any) => {
    setUserName(option.label);
  };

  return (
    <Modal
      open={isVisible}
      onCancel={() => clear()}
      footer={null}
      className="REWARD_MODAL"
      centered
    >
      <div className="shareReward">
        <img src={rewardshare} alt="" />
      </div>
      <div className="rewardTextDiv">
        <h4>Reward sharing</h4>
        <p>
          You can share you reward amount with your friends and make them happy
          as you are
        </p>
        <p style={{ justifyContent: 'center' }} className="points">
          Maximum Amount can be shared:{" "}
          <img
            src={rewardStar}
            style={{ marginLeft: '10px', width: "20px" }}
            alt={"Bag"}
          />
          {settings?.sharedLimit}
        </p>
      </div>
      <Form
        form={form}
        layout="vertical"
        className="rewardForm"
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        onValuesChange={(e) => {
          console.log(e);
          const isBadge = badges?.find(
            (v) => Number(e?.points) === Number(v?.points)
          );
          e.points && setBadgeId(isBadge ? isBadge?._id : null);
        }}
      >
        <Col xs={24}>
          <div
            className="bagde_container"
            ref={badgeContainerRef}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {!getLimit() &&
              badges
                ?.filter((v) => v?.points <= Number(settings?.sharedLimit))
                ?.map((v, i) => {
                  return (
                    <div
                      key={i}
                      className="badge_box"
                      onClick={() => {
                        // form.setFieldValue("points", v?.points);
                        setBadgeId(v?._id);
                        setPoints(v?.points);
                      }}
                      title={String(v?.points)}
                    >
                      <div
                        className="image_box"
                        style={{
                          backgroundImage: `url(${v?.imageUrl})`,
                          border:
                            v?._id === badgeId
                              ? "4px solid #F06423"
                              : "3px solid rgb(192, 192, 192)",
                        }}
                      />
                      <div className="text_box">
                        <p className="name">{v?.name}</p>
                        <Tooltip title={v?.description}>
                          <p className="description">{v?.description}</p>
                        </Tooltip>
                        <p className="points">
                          <img
                            src={rewardStar}
                            style={{ width: "20px" }}
                            alt={"Bag"}
                          />
                          {v?.points}
                        </p>
                      </div>
                    </div>
                  );
                })}
          </div>
        </Col>

        <Col xs={24} style={{ marginTop: "20px" }}>
          <Form.Item
            label="Select friend"
            name="email"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select
              disabled={getLimit()}
              showSearch
              placeholder={<span className="select-placeholder">Select</span>}
              optionFilterProp="children"
              onChange={getData}
              filterOption={(
                input: string,
                option?: { label: string; value: string }
              ) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={allUsers
                ?.filter((v) => v?._id !== user?._id)
                ?.map((v) => {
                  return {
                    value: v?.email,
                    label: `${v?.firstName} ${v?.lastName}`,
                  };
                })}
            />
          </Form.Item>
        </Col>

        {getLimit() && (
          <Col xs={24} style={{ marginTop: "20px" }}>
            <div className="exceed_limit_box">
              <IoIosAlert color={"red"} size={20} />
              <p>Date Exceeded or Point Share Limit is 0!</p>
            </div>
          </Col>
        )}

        <div className="shareBtn">
          <Button
            disabled={getLimit()}
            loading={loading}
            type="primary"
            className="primaryButton"
            style={{ width: "100%", height: "50px" }}
            htmlType="submit"
          >
            Send
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

RewardModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RewardModal;
