import React, { useEffect, useState } from "react";
import { notification } from "antd";
import Routers from "./Routes";
import { lightTheme, darkTheme } from "../config/theme";
import { useAppSelector } from "../store/hooks";
import { getUser } from "../store/services/auth";
import { socket } from './socket'; // Adjust the import path as needed

import { NotificationType } from "../store/interfaces/Notification";
import Logo from "../assets/images/logo.png";
import { getNotificationUnreadCount } from "../store/services/notification";

const App: React.FC = () => {
  const themeMode = useAppSelector((state) => state.theme.mode);
  const userId = useAppSelector((state) => state.auth.user?._id);
  const isAuthenticated = localStorage.getItem("token");
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {

    const handleOnlineStatusChange = () => {
      setOnline(navigator.onLine);
      if (!navigator.onLine) {
        notification.error({ message: "Check your internet connection" });
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    // Apply theme and fetch user only when necessary
    const setTheme = (themeMode === "lightTheme" ? lightTheme : darkTheme);
    Object.entries(setTheme).forEach(([key, value]) => {
      document.body.style.setProperty(`--${key}`, value);
    });

    if (isAuthenticated && online && !userId) {
      getUser();
    }
  }, [themeMode, isAuthenticated, online, userId]);

  useEffect(() => {
    // Manage socket connections more efficiently
    if (isAuthenticated) {
      socket.connect();
      if (userId) socket.emit("join", userId);

      const handleNotification = async (data: NotificationType) => {
        await getNotificationUnreadCount();
        if(data?.message?.includes("points") || data?.message?.includes("badge")){
          getUser();
        }
        if ("Notification" in window && data?.message) {
          if (Notification.permission === "granted") {
            new Notification("New Notification", { body: data?.message, icon: Logo });
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
              if (permission === "granted") {
                new Notification("New Notification", { body: data?.message, icon: Logo });
              }
            });
          }
        }
      };

      socket.on("notification", handleNotification);

      return () => {
        socket.off("notification", handleNotification);
        socket.disconnect();
      };
    }
  }, [isAuthenticated, userId]);

  return (
    <>
      {online ? <Routers /> : null}
      {!online && (
        <div className="lost_connection">
          <img width={100} src={require("../assets/images/wifi_icon.png")} alt="wifi icon" />
          <p>There is no internet connection!</p>
        </div>
      )}
    </>
  );
};

export default App;
