import { Button, Modal, notification, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../store/hooks";

import moment from "moment";
import { FaEye } from "react-icons/fa6";
import { NotificationStatusIcon } from "../../../config/NotificationConstants";
import { NotificationType } from "../../../store/interfaces/Notification";
import { deleteNotification, getAllNotifications, getNotificationUnreadCount, updateNotifications } from "../../../store/services/notification";
import { MdDeleteOutline } from "react-icons/md";

const Notifications: React.FC = () => {
  const { loading, notifications = [], notificationCount } = useAppSelector((state) => state.notifications);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [mainCount, setMainCount] = useState<number>(0);
  const [btn, setBtn] = useState('unread');
  const [mainNotification, setMainNotification] = useState<any>([])
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [data, setData] = useState<any>(null);
  const [page, setPage] = useState(1);


  const getColumns = () => {
    const columns: any = [
      {
        key: "type",
        render: (_: string, obj: NotificationType) => (
          // @ts-ignore
          NotificationStatusIcon[obj?.type] || <div>No Icon</div>
        )
      },
      {
        dataIndex: "message",
        key: "message",
        render: (_: string, obj: NotificationType) => (
          obj?.message ? (
            obj.message.length > 10 ? (
              <div>
                <p>{obj.message.split(" ").slice(0, 10).join(" ") + " ..."}</p>
                <p style={{ marginTop: 20 }}>{moment(obj.date).format("MM/DD/YY")}</p>
              </div>
            ) : (
              <div>{obj.message}</div>
            )
          ) : <div>No Message</div>
        )
      },
      {
        dataIndex: "imageUrl",
        key: "imageUrl",
        render: (_: string, obj: any) => (
          <div >
            {
              btn === 'read' && <MdDeleteOutline cursor={"pointer"} color="red" size={20} onClick={() => { deleteNotificationData(obj?._id) }} />

            }
            <FaEye cursor={"pointer"} style={{ marginLeft: "10px" }} size={20} onClick={() => showNotificationModal(obj)} />
          </div>
        ),
      }
    ];
    return columns;
  };


  const getData = async () => {
    try {
      const payload = {
        page: page,
        limit: 5,
        read: btn === 'unread' ? false : true
      };
      const res = await getAllNotifications(payload);
      setMainNotification(res.notifications);
      setMainCount(res?.total);
      const unreadCount = await getNotificationUnreadCount();
      setNotificationData(res.data.notifications || []);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };


  const showNotificationModal = async (obj: any) => {
    setData(obj);
    setModalVisible(true);

    if (btn === 'unread') {
      const id = [obj._id];
      try {
        const res = await updateNotifications(id);
        if (res === 200) {
          getData();
        }
      } catch (error) {
        console.error("Failed to update notifications:", error);
      }
    }
  };

  const markAsAllRead = async () => {
    try {
      if (btn === 'read') {
        const notificationids = notifications.length > 0 ? notifications.map((item: any) => item._id) : [];
        const res = await updateNotifications(notificationids);
        if (res === 200) {
          getData();
        }
      }
    } catch (error) {
      console.error("Error marking all as read:", error);
    }
  };

  const deleteNotificationData = async (id: string) => {
    try {
      const res = await deleteNotification({ id: id });
      if (res === 200) {
        getData();
      }
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [page, btn]);

  return (
    <div>
      <div className="notification_settings_Header_Container">
        <div className="notification_read_main">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "8px",
            }}
          >
            <h2>Notifications</h2>
            {
              btn === 'Unread' ? <p>You {notificationCount} notifications to go through</p> :
                <p>You 0 notifications to go through</p>
            }
          </div>
          {/* {
            btn !== 'read' && <Button onClick={markAsAllRead} className="notifiction_ReadBtn">
              Mark all as Read
            </Button>
          } */}

        </div>
      </div>
      <div className="notification_btn_main">
        <div onClick={() => { setBtn("unread") }} className={btn === "unread" ? "notifiction_active" : "notifiction_btn"}>
          Unread
        </div>
        <div onClick={() => { setBtn("read") }} className={btn === "read" ? "notifiction_active" : "notifiction_btn"}>
          Read
        </div>
      </div>
      <div className="notification_table_container  table-div">
        <Table
          loading={loading}
          columns={getColumns()}
          dataSource={mainNotification}
          scroll={{ x: 600 }}
          pagination={{
            current: page,
            total: mainCount,
            pageSize: 5,
            onChange: (val) => {
              setPage(val);
            },
            position: ["bottomRight"],
          }}
        />
      </div>
      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <h2 className="notification_modal_heading">{"Message"}</h2>
        <p>{data?.message}</p>
      </Modal>
    </div>
  );
};

export default Notifications;
