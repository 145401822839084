import React, { useEffect, useState } from "react";

import {
  Modal,
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Tooltip,
} from "antd";

import { TbEdit } from "react-icons/tb";

import { useAppSelector } from "../../store/hooks";
import { getAllProducts } from "../../store/services/product";
import {
  CreateGroupWishlistType,
  GroupWishlistType,
} from "../../store/interfaces/GroupWishlist";
import {
  ProductColorType,
  ProductType,
  ProductVariationsType,
  VariationType,
} from "../../store/interfaces/Product";
import { createGroupWishlists } from "../../store/services/groupWishlist";
import { getAllUsers } from "../../store/services/auth";
import { app_url, base_url } from "../../config/constants";

interface Props {
  isEdit?: boolean;
  editObj?: GroupWishlistType;
  getData: () => void;
}

const CreateGroupWishlist: React.FC<Props> = ({ isEdit, editObj, getData }) => {
  const { allUsers } = useAppSelector((state) => state.auth);
  const { allProducts } = useAppSelector((state) => state.products);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [productLoading, setProductLoading] = useState<boolean>(true);

  const [variations, setVariations] = useState<Array<ProductVariationsType>>(
    []
  );
  const [colors, setColors] = useState<Array<ProductColorType>>([]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
    if (isEdit) {
      form.setFieldsValue({
        name: editObj?.name,
        users: editObj?.users?.map((v) => v?._id),
        product: editObj?.product,
        size: editObj?.size,
        color: editObj?.color,
        points: editObj?.points,
      });
    }
    getUsers();
    getProducts();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setVariations([]);
  };

  const getUsers = async () => {
    !userLoading && setUserLoading(true);
    await getAllUsers();
    setUserLoading(false);
  };
  const getProducts = async () => {
    !productLoading && setProductLoading(true);
    await getAllProducts();
    setProductLoading(false);
  };

  const onFinish = async (values: CreateGroupWishlistType) => {
    let obj: CreateGroupWishlistType = {
      _id: values._id ? values._id : null,
      name: values.name,
      users: values.users,
      product: values.product,
      points: 0,
    };
    if (values?.size) {
      obj = { ...obj, size: values.size, color: values.color };
    }
    isEdit ? await _update(obj) : await _create(obj);
  };

  const _create = async (payload: CreateGroupWishlistType) => {
    setSubmitLoading(true);
    const res = await createGroupWishlists(payload);
    if (res) {
      handleCancel();
      getData();
    }
    setSubmitLoading(false);
  };

  const _update = async (payload: any) => {
    //   setSubmitLoading(true);
    //   const res = await updateSchedule(payload);
    //   if (res) {
    //     handleCancel();
    //     getData();
    //   }
    //   setSubmitLoading(false);
  };

  return (
    <>
      {isEdit ? (
        <TbEdit
          onClick={showModal}
          className="icon"
          style={{ cursor: "pointer" }}
        />
      ) : (
        <Button className="primaryButton" type="primary" onClick={showModal}>
          Create Group Wishlist
        </Button>
      )}
      <Modal
        forceRender
        title={`${isEdit ? "Update Group Wishlist" : "Create Group Wishlist"}`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        className="create_discount_modal"
        centered
      >
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
          onValuesChange={(e) => {
            if (e.product) {
              form.setFieldValue("size", undefined);
              form.setFieldValue("color", undefined);
              const product = allProducts.find(
                (v: ProductType) => v?._id === e.product
              );
              const variations: any = product?.variations ?? [];
              if (product && variations?.length > 0) {
                setVariations(variations);
                setColors([]);
              } else {
                setVariations([]);
                setColors([]);
              }
            }
            if (e.size) {
              form.setFieldValue("color", undefined);
              const variation = variations?.find((v) => v?._id === e.size);

              if (
                variation &&
                variation?.colors &&
                variation?.colors?.length > 0
              ) {
                setColors(variation.colors);
              } else {
                setColors([]);
              }
            }
          }}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Name (Required)" />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Users"
                name="users"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  loading={userLoading}
                  optionFilterProp="children"
                  filterOption={(
                    input: string,
                    option?: { label: string; value: string }
                  ) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase())
                  }
                  mode="multiple"
                  size={"large"}
                  maxTagCount={"responsive"}
                  placeholder="Select Users"
                  className="users_select_box"
                  options={allUsers
                    ?.filter((v) => v?._id !== localStorage.getItem("userId"))
                    ?.map((v) => {
                      return {
                        label: v?.firstName +" " + v?.lastName ,
                        value: v?._id,
                      };
                    })}
                />
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                label="Product"
                name="product"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Select
                  loading={productLoading}
                  optionFilterProp="children"
                  // showSearch
                  // filterOption={(
                  //   input: string,
                  //   option?: { label: string; value: string }
                  // ) =>
                  //   (option?.label ?? "")
                  //   ?.toLowerCase()
                  //   ?.includes(input?.toLowerCase())
                  // }
                  size={"large"}
                  maxTagCount={"responsive"}
                  placeholder="Select Product"
                  className="users_select_box"
                  // options={}
                >
                  {allProducts?.map((v: any) => (
                    <Select.Option key={v?._id} value={v?._id}>
                      <Tooltip placement="topLeft"
                        title={
                          <a target="_blank" href={app_url + "/product-detail/" + v?._id}>
                            View
                          </a>
                        }
                      >
                        <div style={{width:"100%"}}>{v?.name}</div>
                      </Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {variations?.length > 0 && (
              <>
                <Col xs={12}>
                  <Form.Item
                    name={"size"}
                    label="Size"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Size (Required)"
                      options={variations?.map((v) => {
                        return {
                          value: v?._id,
                          label: v?.size,
                        };
                      })}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="color"
                    label="Color"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Color (Required)"
                      labelInValue={false}
                    >
                      {colors?.map((v) => (
                        <Select.Option key={v?._id} value={v?._id}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                width: "16px",
                                height: "16px",
                                backgroundColor: v?.hex,
                                marginRight: "8px",
                              }}
                            />
                            <span>{v?.hex}</span>
                          </div>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col xs={24} style={{ marginTop: "10px" }}>
              <Form.Item>
                <Button
                  type="primary"
                  className="primaryButton"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="primaryButton"
                  loading={submitLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {isEdit ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default CreateGroupWishlist;
