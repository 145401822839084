import store from "..";
import { privateAPI } from "../../config/constants";
import { notification } from "antd";
import { setBadges, setLoading, setSettings } from "../slices/settingSlice";

export const getSettings = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/settings/get-settings");
    if (response) {
      store.dispatch(setSettings(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error: any) {
    console.log("GET SETTINGS ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const getBadges = async () => {
  try {
    const response = await privateAPI.get("/badge/get-actives");
    if (response) {
      store.dispatch(setBadges(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error: any) {
    console.log("GET BADGES ERROR:", error);
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};

export const getSellingProduct = async ()=>{
  try {
    const res = await privateAPI.get("/product/get-most-popular-item");
    if(res?.status === 200){
      return res.data;
    }
  } catch (error) {
    console.log("error product",error);
  }
}