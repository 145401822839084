import { useEffect, useState } from "react";
import { Col, Row, Tooltip, Button, Spin } from "antd";

import { useNavigate } from "react-router-dom";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Points from "../Points";
import GroupWishlistCheckout from "../Modals/GroupWishlistCheckout";

import { useAppSelector } from "../../store/hooks";
import {
  contributeGroupWishlist,
  deleteGroupWishlist,
} from "../../store/services/groupWishlist";

import { HiOutlineTrash } from "react-icons/hi";

const GroupWishlist = ({ groupWishlistId }) => {
  const { singleGroupWishlist } = useAppSelector((state) => state?.wishlist);
  const { user } = useAppSelector((state) => state?.auth);
  const [loading, setLoading] = useState("");
  const productId = singleGroupWishlist?.product?.productId;
  const [windowSize, setWindowSize] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth < 500 ? true : false);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // Prepare the array of image objects for the ImageGallery component
  const imageGalleryItems = productId?.imagesUrl?.map((item, index) => ({
    original: item,
    thumbnail: item,
    thumbnailHeight: 80,
    originalHeight: windowSize ? 250 : 550,
    originalClass: "image-gallery-original",
    thumbnailClass: "image-gallery",
  }));

  const truncateText = (text, maxLength) => {
    if (productId?.name?.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const getProductDetails = () => {
    const variations = singleGroupWishlist?.product?.productId?.variations;
    const size = singleGroupWishlist?.product?.size;
    const color = singleGroupWishlist?.product?.color;
    const points = singleGroupWishlist?.product?.productId?.points;
    const stock = singleGroupWishlist?.product?.productId?.stock;
    const initialPoints = singleGroupWishlist?.initialPoints;

    let obj = {
      size: {
        name: "-",
      },
      color: {
        hex: "-",
      },
      points: 0,
      stock: 0,
      initialPoints: 0,
    };
    if (variations && variations?.length > 0) {
      const isSize = variations?.find((v) => v?._id === size);
      const isColor = isSize?.colors?.find((v) => v?._id === color);
      obj = {
        size: isSize,
        color: isColor,
        points: isColor?.points,
        stock: isColor?.stock,
        initialPoints,
      };
    } else {
      obj = {
        size: "-",
        color: "-",
        points: points,
        stock: stock,
        initialPoints,
      };
    }
    return obj;
  };

  const getTotalPoints = () => {
    const points = getProductDetails()?.points;
    const initialPoints = getProductDetails()?.initialPoints;

    return (
      Number(points) +
      Number(singleGroupWishlist?.product?.productId?.deliveryFee || 0) +
      Number(singleGroupWishlist?.deliveryFee || 0) -
      Number(initialPoints)
    );
  };

  const handleContribute = async () => {
    setLoading("contribute");
    await contributeGroupWishlist({
      _id: singleGroupWishlist?._id,
      points: getTotalPoints() / singleGroupWishlist?.users?.length,
    });
    setLoading("");
  };

  const handleDelete = async () => {
    setLoading("delete");
    const res = await deleteGroupWishlist(singleGroupWishlist?._id);
    if (res) {
      navigate("/group-wishlist");
    }
    setLoading("");
  };

  return (
    <div className="product-details">
      <div className="layoutPaddingInline">
        <Row gutter={[40, 20]}>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <div className="leftProductIMGs">
              <div className="activeIMG" style={{ width: "100%" }}>
                <ImageGallery
                  showNav={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={imageGalleryItems || []}
                />
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <div className="rightProductDetails">
              <div className="rating-wishlist-section"></div>
              <div className="product-name-description">
                <Tooltip title={productId?.name}>
                  <p>{truncateText(productId?.name, 60)}</p>
                </Tooltip>
                <p>{productId?.description}</p>
              </div>
              <div className="price-section">
                <Points points={getProductDetails()?.points} />
              </div>

              {productId?.deliveryFee !== 0 && (
                <div
                  className="points"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>Product Delivery Fee: </label>
                  <Points points={productId?.deliveryFee} />
                </div>
              )}

              {singleGroupWishlist?.deliveryFee !== 0 && (
                <div
                  className="points"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>Global Delivery Fee: </label>
                  <Points points={singleGroupWishlist?.deliveryFee} />
                </div>
              )}

              {getProductDetails()?.initialPoints !== 0 && (
                <div
                  className="points"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <label>Initial Points: </label>
                  <Points points={getProductDetails()?.initialPoints} />
                </div>
              )}

              <div
                className="points"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {getTotalPoints() !== 0 && (
                  <>
                    <label>Total Points: </label>
                    <Points points={getTotalPoints()} />
                  </>
                )}
              </div>
              {(getProductDetails()?.size?.size ||
                getProductDetails()?.color?.hex) && (
                <div style={{ display: "flex", gap: "30px" }}>
                  {getProductDetails()?.size?.size && (
                    <div
                      className="points"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <label>Size: </label>
                      <p>
                        <strong>{getProductDetails()?.size?.size}</strong>
                      </p>
                    </div>
                  )}
                  {getProductDetails()?.color?.hex && (
                    <div className="colorSelection">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: ".3rem",
                        }}
                      >
                        <p style={{ color: "var(--darkgray)" }}>Color:</p>
                        <div style={{ display: "flex", gap: ".3rem" }}>
                          <div
                            className="colors1"
                            style={{
                              background: getProductDetails()?.color?.hex,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                className="points"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {getTotalPoints() /
                  singleGroupWishlist?.users?.length.toFixed(2) !==
                  0 && (
                  <>
                    <label>Contributed Points: </label>
                    <Points
                      points={(
                        getTotalPoints() / singleGroupWishlist?.users?.length
                      ).toFixed(2)}
                    />
                  </>
                )}
              </div>

              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  disabled={
                    singleGroupWishlist?.contributed?.find(
                      (v) => v?._id === user?._id
                    ) !== undefined ||
                    singleGroupWishlist?.status !== "approved"
                  }
                  type="primary"
                  className="primaryButton"
                  style={{ paddingInline: "20px", height: "45px" }}
                  onClick={handleContribute}
                  loading={loading === "contribute"}
                >
                  Contribute
                </Button>
                {singleGroupWishlist?.adminUser === user?._id &&
                  singleGroupWishlist?.contributed?.length ===
                    singleGroupWishlist?.users?.length && (
                    <GroupWishlistCheckout
                      groupWishlistId={groupWishlistId}
                      stock={getProductDetails()?.stock}
                    />
                  )}
              </div>

              {singleGroupWishlist?.status === "pending" && (
                <div>
                  {loading === "delete" ? (
                    <Spin />
                  ) : (
                    <HiOutlineTrash
                      size={22}
                      color="red"
                      onClick={handleDelete}
                      className="cursor"
                    />
                  )}
                </div>
              )}

              {getProductDetails()?.stock < 0 && (
                <div className="points">
                  <p style={{ color: "var(--mainRed)" }}>Out of stock</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GroupWishlist;
