import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import { Row, Col } from "antd";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import CustomerReview from "../../../components/CustomerReview";
import Loader from "../../../components/Loader";
import GroupWishlist from "../../../components/GroupWishlist";

import { useAppSelector } from "../../../store/hooks";
import { getSingleGroupWishlist } from "../../../store/services/groupWishlist";

import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

const GroupWishlistDetail: React.FC = () => {
  const { singleGroupWishlist, loading } = useAppSelector(
    (state) => state?.wishlist
  );
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleGroupWishlist(id || "");
  }, [id]);

  return (
    <div className="productDetail">
      <Header />
      <div className="layoutPaddingInline">
        {loading ? (
          <Loader spin />
        ) : (
          <>
            <GroupWishlist groupWishlistId={id} />
            <div style={{ paddingBlock: "40px" }}>
              <Row gutter={[20, 20]}>
                <Col xs={24}>
                  <h2>{singleGroupWishlist?.name}</h2>
                </Col>
                <Col xs={24}>
                  <h3>Users ({singleGroupWishlist?.users?.length})</h3>
                </Col>
                {singleGroupWishlist?.users?.map((v, i) => {
                  return (
                    <>
                      <Col xs={24} sm={8}>
                        <p>
                          {i + 1}. {v?.firstName} {v?.lastName}
                        </p>
                      </Col>
                    </>
                  );
                })}
                <Col xs={24}>
                  <h3>
                    Contributed ({singleGroupWishlist?.contributed?.length})
                  </h3>
                </Col>
                {singleGroupWishlist &&
                singleGroupWishlist?.contributed?.length > 0 ? (
                  singleGroupWishlist?.contributed?.map((v, i) => {
                    return (
                      <>
                        <Col xs={24} sm={8}>
                          <p>
                            {i + 1}) {v?.firstName} {v?.lastName}
                          </p>
                        </Col>
                        <Col xs={24} sm={16}>
                          <p>
                            {v?.email}{" "}
                            <IoCheckmarkDoneCircleSharp
                              color="var(--mainGreen)"
                              style={{ marginBottom: "-2px" }}
                            />
                          </p>
                        </Col>
                      </>
                    );
                  })
                ) : (
                  <Col xs={24}>
                    <p>No Contributers</p>
                  </Col>
                )}
              </Row>
            </div>
          </>
        )}
      </div>
      <CustomerReview />
      <Footer />
    </div>
  );
};

export default GroupWishlistDetail;
