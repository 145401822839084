import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Points from "../Points";

import { GroupWishlistType } from "../../store/interfaces/Wishlist";
import { firstLetterCap } from "../../utils";

interface Props {
  data: GroupWishlistType;
  loading?: boolean;
}

const GroupWishlistCard: React.FC<Props> = ({ data }) => {
  const {
    product: { productId },
  } = data;

  const navigate = useNavigate();

  const getsizeAndColor = () => {
    const variations = data?.product?.productId?.variations;
    const size = data?.product?.size;
    const color = data?.product?.color;
    const points = data?.product?.productId?.points;

    let obj: any = {
      size: {
        name: "-",
      },
      color: {
        hex: "-",
      },
      points: 0,
    };
    if (variations && variations?.length > 0) {
      const isSize = variations?.find((v) => v?._id === size);
      const isColor = isSize?.colors?.find((v) => v?._id === color);
      obj = {
        size: isSize,
        color: isColor,
        points: isColor?.points,
      };
    } else {
      obj = {
        size: "-",
        color: "-",
        points: points,
      };
    }
    return obj;
  };

  const getColor = (status: string) => {
    const obj: any = {
      pending: "orange",
      approved: "green",
      cancelled: "red",
    };
    return obj[status];
  };

  return (
    <div
      className="product_card_wrapper groupWishImage"
      style={{ height: "100%"}}
      
      onClick={() => navigate(`/group-wishlist-detail/${data?._id}`)}
    >
      <div className="product_card">
        <div
          className="product_image "
          style={{ backgroundImage: `url(${productId?.imagesUrl?.[0]})` }}
        >
          {/* <div className="OutOfStock">
            {selectedColor?.stock && selectedColor?.stock < 1 ? (
              <p>Out of Stock</p>
            ) : null}

            {!selectedColor?.stock && data?.stock < 1 ? (
              <p>Out of Stock</p>
            ) : null}
          </div> */}
        </div>
        <div className="product_detail">
          <p className="product_name">{productId?.name}</p>
          <p className="product_description">{productId?.description}</p>
          <div className="points_box">
            <div className="pb_left">
              <p style={{ color: getColor(data?.status) }}>
                {firstLetterCap(data?.status)}
              </p>
            </div>
            <div className="pb_right">
              <span className="points">
                <Points points={getsizeAndColor()?.points} />
              </span>
            </div>
          </div>

          {/* <div className="color_and_size_box">
            {selectedSize?.colors?.length > 0 && (
              <div>
                <p>Color</p>
                <div>
                  {selectedSize?.colors?.slice(0, 6)?.map((item: ColorType) => (
                    <div
                      key={item?._id}
                      className="color_div"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        background: item?.hex,
                        border:
                          selectedColor?._id === item?._id
                            ? "2px solid var(--black)"
                            : "none",
                      }}
                      onClick={() => setSelectedColor(item)}
                    />
                  ))}
                </div>
              </div>
            )}
            <div>
              {selectedSize?.size === "Fixed" ||
                ((data?.variations ? data?.variations?.length > 0 : false) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0px",
                    }}
                  >
                    <p>Size</p>
                    <div style={{ display: "flex", gap: "3px" }}>
                      {data?.variations?.map(
                        (item: VariationType, i: number) => (
                          <p
                            key={item?._id}
                            style={{
                              fontWeight:
                                item?._id === selectedSize?._id ? 800 : 400,
                              cursor: "pointer",
                              color: "var(--black)",
                            }}
                            onClick={() => setSelectedSize(item)}
                          >
                            {item?.size || "-"}
                          </p>
                        )
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default GroupWishlistCard;
