import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterestP } from "react-icons/fa6";
// import { Button, Input } from "antd";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main_footer_container layoutPaddingInline">
      {/* <div className="subscribe_box">
        <h1>Subscribe to our newsletter</h1>
        <p>To make your stay special and even more memorable</p>
        <div className="input_email_box">
          <Input placeholder="Enter your work email" />
          <Button type="primary" className="primaryButton">
            Subscribe
          </Button>
        </div>
      </div> */}
      {/* FOOTER HEADER */}
      <div className="footer-header">
        <h2 className="description_text">
          Quality Impactful Support that Lasts a Lifetime
        </h2>
        <div className="core-values">
          <p className="description_text">
            Core Values:
            <br />
            Service ➤ Client Success ➤ Teamwork ➤ Passion ➤ Do Not Harm
          </p>
        </div>
        <div className="footer-address">
          <p>Broward: 4800 N. University Drive, Lauderhill, FL 33351</p>
          <p>admin@obctherapy.com</p>
        </div>
      </div>
      {/* <hr className="line" /> */}
      {/* <div className="footer_bottom">
        <div className="left">
          <div className="logo_box">
            <img src={require("../../assets/images/logo.png")} alt="logo" />
          </div>
          <p className="description_text">
            Quality Impactful Support that Lasts a Lifetime
          </p>
          <p className="description_text">
            Core Values:
            <br/>
            Service ➤ Client Success ➤ Teamwork  ➤ Passion  ➤ Do Not Harm   
          </p>
          <div>
            <p>Boward: 4800 N. University Drive, Lauderhill, FL 33351</p>
            <p>Palm Beach: 5205 Village Blvd, West Palm Beach, FL 33407</p>
          </div>
          <p>(954)-532-0337</p>
          <p>humanresources@obctherapy.com</p>
        </div>

        <div className="center">
          <p className="heading_top">Support</p>
          <p className="links" onClick={() => handleNavigate("/about-us")}>
            {" "}
            About Us{" "}
          </p>
          <p className="links" onClick={() => handleNavigate("/contact")}>
            {" "}
            Contact Us{" "}
          </p>
          <p className="heading_top">Terms & Policies</p>
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
          <p>Explore</p>
        </div>

        <div className="right">
          <p className="heading_top">Follow Us</p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.facebook.com/omegacenterforautism/">Facebook</a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.instagram.com/omegacenterforautism/?hl=en">Instagram</a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.linkedin.com/company/omegacenterforautism">LinkedIn</a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.pinterest.com/omegacenterforautism/">Pinterest</a>
          </p>
        </div>
      </div> */}
      <div className="footer-link-container">
        <div className="footer-link-start">
          <div className="page-links">
            <p className="links" onClick={() => handleNavigate("/about-us")}>
              About Us
            </p>
            <p className="links" onClick={() => handleNavigate("/contact")}>
              Contact Us
            </p>
          </div>
          <div className="line" />
        </div>
        <div className="footer-social-icon">
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.instagram.com/omegacenterforautism/?hl=en">
              <FaInstagram size={30}/>
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.linkedin.com/company/omegacenterforautism">
              <FaLinkedin size={30} />
            </a>
          </p>
          <p>
            <div className="logo_box">
              <img src={require("../../assets/images/logo.png")} alt="logo" />
            </div>
          </p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.pinterest.com/omegacenterforautism/">
              <FaPinterestP size={30} />
            </a>
          </p>
          <p>
            <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://www.facebook.com/omegacenterforautism/">
              <FaFacebook size={30}/>
            </a>
          </p>
          {/* </div> */}
        </div>
        <div className="footer-link-end">
          <div className="page-links">
            <p className="links">
              <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://sites.google.com/view/teamoca-digest/kpi-system/oca-shopping-mall?authuser=0">
                Shopping Mall: Learn More 
              </a>
            </p>
            <p className="links">
              <a target="_blank" rel="noreferrer" className="footer_social_text" href="https://sites.google.com/view/teamoca-digest/home?authuser=0">
                Team OCA Digest 
              </a>
            </p>
          </div>
          <div className="line" />
        </div>
      </div>
      <div className="copyright_box">
        <p>
          ©{new Date().getFullYear()} Omega Center for Autism. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
