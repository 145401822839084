import React from "react";
import Header from "../../../components/Header";

const HeroSection: React.FC = () => {
  return (
    <div className="hero_section_container">
      <Header />
      <div className="content">
        <h1>OCA Shopping Mall<br /> <span>Well-Earned Points at Work for You</span></h1>
        <p>
        Explore the vast array of incredible deals waiting for you,<br/>
        all thanks to your hard-earned points.<br/>
        Explore, shop, and save like never before
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
