import { privateAPI } from "../../config/constants";

export const getDataByCategory = async (data:{id:string})=>{
    try {
        const res = await privateAPI.post("/category/getProduct",data);
        return res;
    } catch (error:any) {
        console.log("error",error.message);
    }
}

export const getSubProduct = async (id:string,currentPage:number,perPAge:number, search: string)=>{
    try {
        const res = await privateAPI.post("/product/get-product-bySubcategory",{subCategory:id,page:currentPage,perPage:perPAge, search: search});
        if(res.status ===200){
            return res;
        }
    } catch (error) {
        console.log("error",error);
    }
}