import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";

import Header from "../../../components/Header";
import Banner from "../../../components/Banner";
import Footer from "../../../components/Footer";
import Loader from "../../../components/Loader";
import GroupWishlistCard from "../../../components/GroupWishlistCard";
import CreateGroupWishlist from "../../../components/Modals/CreateGroupWishlist";

import emptyCartIMG from "../../../assets/images/emptyCartIMG.png";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setGlobalSearch } from "../../../store/slices/productSlice";
import { getGroupWishlist } from "../../../store/services/groupWishlist";
import { GroupWishlistType } from "../../../store/interfaces/GroupWishlist";

const GroupWishlist: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { groupWishlist } = useAppSelector((state) => state?.wishlist);
  const { search } = useAppSelector((state) => state.products);
  const [allWishlist, setAllWishlist] = useState<Array<GroupWishlistType>>([]);
  const [ownWishlist, setOwnWishlist] = useState<Array<GroupWishlistType>>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getData();
    return () => {
      dispatch(setGlobalSearch(""));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const filterd: any = groupWishlist?.filter(
      (v: any) =>
        v?.status === "approved" &&
        v?.product?.productId?.name
          .toLowerCase()
          .includes(search.toLowerCase().trim())
    );
    setAllWishlist(filterd);
    // eslint-disable-next-line
  }, [groupWishlist, search]);

  useEffect(() => {
    const filterd: any = groupWishlist?.filter(
      (v: any) =>
        (v?.status === "pending" || v?.status === "cancelled") &&
        v?.adminUser === localStorage.getItem("userId")
    );
    setOwnWishlist(filterd);
    // eslint-disable-next-line
  }, [groupWishlist]);

  const getData = async () => {
    setLoading(true);
    await getGroupWishlist();
    setLoading(false);
  };

  return (
    <div className="Wishlist">
      <Header />
      <div className="layoutPaddingInline">
        <Banner location={"group-wishlist"} />
      </div>

      <div
        className="layoutPaddingInline"
        style={{ paddingBlock: "80px", width: "100%" }}
      >
        <div className="heading_top_box">
          <h2 className="emptyCardHeading">Group Wishlist</h2>
          <CreateGroupWishlist getData={getData} />
        </div>
        <Row gutter={[20, 20]}>
          {loading ? (
            <Loader spin />
          ) : (
            <>
              {allWishlist?.length > 0 ? (
                allWishlist?.map((v: GroupWishlistType, i: number) => {
                  return (
                    <Col key={i} xs={24} sm={12} lg={8} xl={6} xxl={6}>
                      <GroupWishlistCard data={v} />
                    </Col>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="emptyCart">
                    <img width={"40%"} src={emptyCartIMG} alt="emptyCart" />
                    <p className="emptyCartText">
                      There are no group wishlist yet.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </Row>
        <div className="emptyCardHeading" style={{ marginBlock: "20px" }}>
          Own Created
        </div>
        <Row gutter={[20, 20]}>
          {loading ? (
            <Loader spin />
          ) : (
            <>
              {ownWishlist?.length > 0 ? (
                ownWishlist?.map((v: GroupWishlistType, i: number) => {
                  return (
                    <Col key={i} xs={24} sm={12} lg={8} xl={6} xxl={6}>
                      <GroupWishlistCard data={v} />
                    </Col>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="emptyCart">
                    <img width={"40%"} src={emptyCartIMG} alt="emptyCart" />
                    <p className="emptyCartText">
                      There are no group wishlist yet.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </Row>
      </div>

      {/* {loading ? (
        <div className="empty_cart">
          <Spin />
        </div>
      ) : recommendProducts?.products?.length ? (
        <>
          <div
            className="layoutPaddingInline"
            style={{ paddingBlock: "80px", width: "100%" }}
          >
            <div className="emptyCardHeading">just for you 💝</div>
            <Row gutter={[20, 20]}>
              {recommendProducts &&
                recommendProducts?.products
                  ?.slice(0, productSlice)
                  ?.map((v: ProductType, i: number) => {
                    return (
                      <Col key={i} xs={24} sm={12} lg={8} xl={6} xxl={6}>
                        <ProductCard data={v} />
                      </Col>
                    );
                  })}
            </Row>
          </div>
          {productSlice < recommendProducts?.products?.length && (
            <button
              onClick={handleLoadMore}
              className="primaryButton customButton"
              style={{ marginBottom: "50px" }}
            >
              Load more
            </button>
          )}
        </>
      ) : (
        <div className="empty_cart">
          <p>Products are not Avaliable</p>
        </div>
      )} */}

      <Footer />
    </div>
  );
};

export default GroupWishlist;
