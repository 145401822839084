import { FaBoxes } from "react-icons/fa";
import { FaCoins, FaUser } from "react-icons/fa6";
import { MdNotifications } from "react-icons/md";
import { LuBadge } from "react-icons/lu";

export const NotificationStatusIcon = {
    points: (
      <FaCoins
        size={40}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          background: 'var(--primary)',
          color: 'white',
          borderRadius: '20px',
        }}
      />
    ),
    badge: (
      <LuBadge
        size={40}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          background: 'var(--primary)',
          color: 'white',
          borderRadius: '20px',
        }}
      />
    ),
    order: (
      <FaBoxes
      size={40}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        background: 'var(--primary)',
        color: 'white',
        borderRadius: '20px',
      }}
      /> 
    ),
    profile: (
      <FaUser
      size={40}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        background: 'var(--primary)',
        color: 'white',
        borderRadius: '20px',
      }}
      /> 
    ),
    undefined: (
      <MdNotifications
        size={40}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px',
          background: 'var(--primary)',
          color: 'white',
          borderRadius: '20px',
        }}
      />
    ),
  };