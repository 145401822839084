import { notification } from "antd";
import { privateAPI } from "../../config/constants";
import store from "../../store";
import {
  setGroupWishlist,
  setLoading,
  setSingleGroupWishlist,
} from "../slices/wishlistSlice";
import { getUser } from "./auth";
import { CreateGroupWishlistType } from "../interfaces/GroupWishlist";

const Created = "Group Wishlist Created Successfully";
// const Updated = "Group Wishlist Updated Successfully";
const Deleted = "Group Wishlist Deleted Successfully";

export const createGroupWishlists = async (
  payload: CreateGroupWishlistType
) => {
  try {
    const response = await privateAPI.post(
      "/groupwishlist/user-create",
      payload
    );
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Created,
      });
      getGroupWishlist();
    }
    return response;
  } catch (error: any) {
    console.log("Create Group Wishlist Error:", error);
    notification.error({
      message: error?.response?.data?.message || "Server Error",
    });
  }
};

export const getGroupWishlist = async () => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get("/groupwishlist/get-all");
    if (response) {
      store.dispatch(setGroupWishlist(response?.data?.data));
    }
    return response?.data?.data;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message,
    });
  }
  finally{
    store.dispatch(setLoading(false));
  }
};

export const getSingleGroupWishlist = async (id: string) => {
  try {
    store.dispatch(setLoading(true));
    const response = await privateAPI.get(`groupwishlist/get-one?_id=${id}`);
    if (response) {
      store.dispatch(setSingleGroupWishlist(response?.data?.data));
    }
    return response.data.data;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message,
    });
  } finally {
    store.dispatch(setLoading(false));
  }
};

export const contributeGroupWishlist = async (payload: any) => {
  try {
    const response = await privateAPI.post(`groupwishlist/contribute`, payload);
    if (response) {
      await getSingleGroupWishlist(payload?._id);
      await getUser();
    }
    return response;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message,
    });
  }
};

export const groupWishlistCheckout = async (payload: any) => {
  try {
    const response = await privateAPI.post(
      `order/create-group-wishlist-order`,
      payload
    );
    if (response) {
      notification.success({
        message:
          response.data.data.message || "Your order created successfully",
      });
    }
    return response;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};

export const deleteGroupWishlist = async (id: string) => {
  try {
    const response = await privateAPI.post(`/groupwishlist/delete`, {
      _id: id,
    });
    if (response) {
      notification.success({
        message: response?.data?.data?.message || Deleted,
      });
    }
    return response;
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Server error",
    });
  }
};
